import React from 'react'
import Layout from '../components/layout'
import bootstrapStyles from "../assets/styles/bootstrap-grid.module.css"

const About = () => (
    <Layout>
        <div className={bootstrapStyles.container}>
            <h1>This is About page.</h1>
        </div>
    </Layout>
)

export default About